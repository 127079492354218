/* src/assets/css/profile.css */
@import "./variables.css";

.profile-page {
  padding: 20px;
}

.filter-section {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-section .search-input {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--border-color);
  /* border-radius: 4px; */
  border-radius: 120px;
  font-size: 16px;
}

.users-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.user-block {
  background-color: var(--search-area-bg);
  color: var(--main-color);
  /* border-radius: 8px; */
  border-radius: 16px;
  box-shadow: var(--box-shadow);
  width: calc(33.333% - 20px);
  padding: 15px;
  position: relative;
  cursor: pointer;
  transition: transform 0.2s;
  display: flex;
  align-items: center;
}

.user-block:hover {
  transform: translateY(-5px);
}

.user-block .user-info-container {
  display: flex;
  align-items: center;
}

.user-block .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.user-block .user-info {
  flex: 1;
}

.user-block .user-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.user-block .user-group {
  font-size: 14px;
  color: var(--text-muted);
  margin-bottom: 5px;
}

.user-block .user-details span {
  display: block;
  font-size: 14px;
  color: var(--text-muted);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

.modal.open {
  opacity: 1;
  transform: translateY(0);
}

.modal-content {
  background: var(--search-area-bg);
  color: var(--main-color);
  border-radius: 8px;
  width: 80%;
  max-width: 1000px;
  position: relative;
  padding: 20px;
  box-shadow: var(--box-shadow);
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: var(--main-color);
}

.modal-body {
  display: flex;
  gap: 20px;
}

.left-section,
.right-section {
  flex: 1;
}

.divider {
  height: 2px;
  background: var(--border-color);
  margin: 10px 0;
}

.user-profile {
  display: flex;
  align-items: center;
}

.avatar-18 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.user-main-info .user-name {
  font-size: 24px;
  /* font-size: 18px; */
  font-weight: bold;
}

.user-main-info .user-group {
  font-size: 18px;
  color: var(--text-muted);
}

.user-additional-info .info-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-additional-info label {
  width: 150px;
  /* font-weight: bold; */
}

.user-additional-info span,
.user-additional-info input,
.user-additional-info select {
  flex: 1;
}

.user-additional-info input,
.user-additional-info select {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.edit-button,
.save-button,
.delete-button {
  background-color: var(--button-bg);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 200px;
  cursor: pointer;
}

.document-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.document-buttons button {
  padding: 10px 20px;
  border: 1px solid var(--border-color);
  background: none;
  cursor: pointer;
  border-radius: 4px;
}

.document-buttons .active {
  background-color: var(--button-bg);
  color: #fff;
  border-color: var(--button-bg);
}

.document-content .document-details {
  padding: 15px;
  border-radius: 4px;
}

.document-content .document-row {
  display: flex;
  margin-bottom: 10px;
  gap: 20px;
}

.document-content label {
  width: 150px;
  /* font-weight: bold; */
}

.document-content span {
  flex: 1;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  color: var(--main-color);
}

.pagination-left,
.pagination-center,
.pagination-right {
  display: flex;
  align-items: center;
}

.pagination-center button {
  background-color: var(--search-area-bg);
  color: var(--main-color);
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
  box-shadow: var(--box-shadow);
  box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2),
    0 24px 20px -24px rgba(71, 82, 107, 0.1);
}

.pagination-center button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.pagination-right select {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--search-area-bg);
  color: var(--main-color);
}
.spancolor {
  color: #888;
  font-size: 0.8rem;
}
.user-additional-info {
  margin-top: 20px;
}
/* .document-content{
    margin-top: 60px;
} */
