/* src/assets/css/newsManagement.css */
@import './variables.css';
@import '~react-quill/dist/quill.snow.css';

.news-management {
  padding: 20px;
}

.news-management h2 {
  margin-bottom: 20px;
  color: var(--main-color);
}

.news-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.news-card {
  background-color: var(--search-area-bg);
  color: var(--main-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  transition: transform 0.2s;
}

.news-card:hover {
    transform: translateY(-5px);
}

.news-content {
  max-width: 80%;
}

.news-content h3 {
  margin-top: 0;
  font-size: 1.5rem;
}

.news-description {
  margin: 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ограничение по строкам */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-description * {
  max-width: 100%;
}

.news-meta {
  display: flex;
  gap: 15px;
  font-size: 0.9rem;
  color: var(--text-muted);
}

.news-actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: flex-end;
    align-items: center;
}

.news-actions button {
    background-color: transparent;
    border: none;
    color: var(--main-color);
    font-size: 1.5rem;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;color: var();
}

.news-actions button:hover {
  background-color: var(--main-color);
  color: var(--search-area-bg);
}

/* Плавающая кнопка */
.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--search-area-bg);
    color: var(--main-color);
    border: none;
    border-radius: 50%;
    padding: 15px;
    width: 60px;
    height: 60px;
    font-size: 1.5rem;
    display: flex;
    cursor: pointer;
    box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
    transition: background-color 0.3s;
    justify-content: center;
    align-items: center;
}
.dark .floating-button{
    box-shadow: var(--box-shadow);
}

.pcolor{
    color: var(--main-color);
    font-size: 1.2rem;
}

.floating-button:hover {
  background-color: var(--link-color-hover);
}

/* Модальное окно */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: var(--search-area-bg);
  color: var(--main-color);
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content form label {
  display: block;
  margin-bottom: 10px;
}

.modal-content form input[type="text"] {
    width: 100%;
    padding: 8px;
    border: none;
    outline: none;
    border-radius: 4px;
    background-color: var(--search-area-bg);
    border-radius: 200px;
    padding: 10px 20px;
    color: var(--main-color);
    box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
}
.dark .modal-content form input[type="text"] {
    box-shadow: none;
}
.modal-content form .modal-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}


.modal-content form input[type="text"]::placeholder {
    color: var(--main-color);
    opacity: 0.6;
  }

.save-button,
.cancel-button,
.close-button {
    padding: 10px 20px;
    background-color: var(--search-area-bg) !important;
    color: var(--main-color) !important;
    border: none;
    border-radius: 200px;
    cursor: pointer;
}


.close-button {
  background-color: #ccc;
  color: var(--main-color);
}

.save-button:hover,
.cancel-button:hover,
.close-button:hover {
  opacity: 0.8;
}

.select-container {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  


.ql-toolbar.ql-snow{
    border: 1px solid var(--main-color) !important; 
    border-radius: 8px 8px 0 0;
    
}

.ql-toolbar.ql-snow + .ql-container.ql-snow{
    border: 1px solid var(--main-color);
    border-top: none;
    border-radius: 0 0 8px 8px;
}
.ql-editor{
    min-height: 200px;
}
.ql-header{
    color: var(--main-color);
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
    background-color: var(--search-area-bg);
}
.selectcustom{
    border: none;
    flex: 1 1;
    outline: none;
    height: 100%;
    padding: 0 20px;
    background-color: var(--search-area-bg);
    color: var(--main-color);
    flex: 1 1;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 120px;
    padding: 9px 20px;
}