/* src/assets/css/tasks.css */
@import './variables.css';

.tasks {
  padding: 20px;
}

.tasks-header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.tasks-header input[type="text"] {
  border-radius: 20px;
  background-color: var(--search-area-bg);
  height: 40px;
  flex: 1;
  padding: 0 20px;
  font-size: 16px;
  outline: none;
  color: var(--light-font);
  box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
  overflow: hidden;
}

.dark .tasks-header input[type="text"] {
  box-shadow: none;
}

.status-filters {
  display: flex;
  align-items: center;
}

.status-filters select {
  padding: 10px 15px;
  background-color: var(--search-area-bg);
  color: var(--main-color);
  border: none;
  border-radius: 200px;
  cursor: pointer;
  font-size: 16px;
  outline: none;
}

.status-filters select option {
  background-color: var(--search-area-bg);
  color: var(--main-color);
}

.application-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.application-card {
  background-color: var(--search-area-bg);
  color: var(--main-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  position: relative;
}

.status-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 15px;
}

.status-circle.red {
  background-color: #CF2458;
}

.status-circle.yellow {
  background-color: #FFC107;
}

.status-circle.green {
  background-color: #17bba7;
}

.status-circle.gray {
  background-color: gray;
}

.application-info h3 {
  margin: 0;
}

.application-info p {
  margin: 5px 0;
}

.application-actions {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.action-button {
  background-color: transparent;
  border: none;
  color: var(--main-color);
  font-size: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-button:hover {
  background-color: var(--main-color);
  color: var(--search-area-bg);
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-left {
  margin-right: auto;
}

.pagination-center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination-center button {
  padding: 5px 10px;
  background-color: var(--button-bg);
  color: var(--main-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-center button:disabled {
  opacity: 0.5;
  cursor: default;
}

.pagination-center span {
  font-size: 1rem;
}

.tasks-header input[type="text"]::placeholder {
  color: var(--main-color);
  opacity: 0.6;
}

/* Модальное окно */
.tasks-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.tasks-modal {
  background-color: var(--search-area-bg);
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.tasks-modal h2 {
  margin-top: 0;
}

.tasks-modal label {
  display: block;
  margin-bottom: 10px;
  color: var(--main-color);
}

.tasks-modal input[type="date"] {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--main-color);
  border-radius: 4px;
  background-color: transparent;
  color: var(--main-color);
}

.tasks-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.tasks-modal-actions button {
  padding: 10px 20px;
  background-color: var(--button-bg);
  color: var(--main-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tasks-modal-actions button:hover {
  background-color: var(--main-color);
  color: var(--button-bg);
}
.avatar{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}
.application-info{
    background-color: var(--search-area-bg);
    color: var(--main-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    position: relative;
}