/* src/assets/css/variables.css */
@import url('https://use.fontawesome.com/releases/v6.6.0/css/all.css');

:root {
    --app-container: #f3f6fd;
    --main-color: #1f1c2e;
    --secondary-color: #4A4A4A;
    --link-color: #1f1c2e;
    --link-color-hover: #c3cff4;
    --link-color-active: #fff;
    --link-color-active-bg: #1f1c2e;
    --projects-section: #fff;
    --message-box-hover: #fafcff;
    --message-box-border: #e9ebf0;
    --more-list-bg: #fff;
    --more-list-bg-hover: #f6fbff;
    --more-list-shadow: rgba(209, 209, 209, 0.4);
    --button-bg: #1f1c24;
    --search-area-bg: #fff;
    --star: #1ff1c2e;
    --message-btn: #fff;
    --text-muted: #393939;
  }
  
  .dark {
    --app-container: #1f1d2b;
    --main-color: #fff;
    --secondary-color: rgba(255, 255, 255, 0.8);
    --link-color: rgba(255, 255, 255, 0.8);
    --link-color-hover: rgba(195, 207, 244, 0.1);
    --link-color-active-bg: rgba(195, 207, 244, 0.2);
    --button-bg: #1f2937;
    --search-area-bg: #1f2937;
    --message-box-hover: #243244;
    --message-box-border: rgba(255, 255, 255, 0.1);
    --star: #ffd92c;
    --light-font: rgba(255, 255, 255, 0.8);
    --more-list-bg: #2f3142;
    --more-list-bg-hover: rgba(195, 207, 244, 0.1);
    --more-list-shadow: rgba(195, 207, 244, 0.1);
    --message-btn: rgba(195, 207, 244, 0.1);
    --text-muted: #c5c5c5;
  }
  
  *,.app-sidebar, .dark :root {
    transition: background-color 0.3s, color 0.1s;
  }
  