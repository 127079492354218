/* src/assets/css/sidebar.css */
@import './variables.css';

.app-sidebar {
  width: 80px;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease;
  background-color: var(--app-container);
  overflow: hidden;
}

.app-sidebar.expanded {
  width: 240px;
}

.app-sidebar-link {
  color: var(--link-color);
  margin: 16px 0;
  transition: all 0.3s ease;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: relative;
}

.app-sidebar.expanded .app-sidebar-link {
  width: 100%;
  height: auto;
  border-radius: 8px;
  justify-content: flex-start;
  padding: 8px;
}

.app-sidebar-link .link-icon {
  font-size: 24px;
}
.link-text {
    margin-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 0;
    opacity: 0;
    transition: max-width 0.3s ease, opacity 0.3s ease;
  }
  .app-sidebar.expanded .link-text {
    max-width: 200px; /* Настройте по необходимости */
    opacity: 1;
  }
.app-sidebar.expanded .app-sidebar-link .link-icon {
  margin-right: 16px;
}

.app-sidebar-link span {
  display: none;
}

.app-sidebar.expanded .app-sidebar-link span {
  display: inline;
  font-size: 16px;
  font-weight: 500;
}

.app-sidebar-link:hover {
  background-color: var(--link-color-hover);
  color: var(--link-color-active);
}

.app-sidebar-link.active {
  background-color: var(--link-color-active-bg);
  color: var(--link-color-active);
}

.app-sidebar-link.active::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  /* border: 2px solid var(--link-color-active); */
  border-radius: 50%;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

.app-sidebar.expanded .app-sidebar-link.active::after {
  border-radius: 8px;
}
.app-sidebar, .dark :root {
    transition: background-color 0.3s, color 0.1s, width 0.3s;

  }

/* Дополнительные стили для плавности */
.app-sidebar-link,
.app-sidebar-link .link-icon,
.app-sidebar-link span {
  transition: all 0.3s ease;
}
