:root, .dark :root {
    transition: background-color 0.3s, color 0.3s;
  }
.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 24px;
    background-color: var(--app-container);
  }
  
  .app-header-left,
  .app-header-right {
    display: flex;
    align-items: center;
  }
  
  .app-header-left {
    flex-grow: 1;
  }
  
  .app-header-right button {
    margin-left: 10px;
  }
  
  .app-icon {
    width: 26px;
    height: 2px;
    border-radius: 4px;
    background-color: var(--main-color);
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
    padding: 3px;
  }
  
  .app-icon:before,
  .app-icon:after {
    content: '';
    position: absolute;
    
    width: 12px;
    height: 2px;
    border-radius: 4px;
    background-color: var(--main-color);
    left: 50%;
    transform: translateX(-50%);
  }
  
  .app-icon:before {
    top: -6px;
  }
  
  .app-icon:after {
    bottom: -6px;
  }
  
  .app-icon.rotated {
    transform: rotate(90deg);
  }
  
  .app-name {
    color: var(--main-color);
    margin: 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 32px;
  }

.mode-switch {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search-wrapper {
  border-radius: 20px;
  background-color: var(--search-area-bg);
  padding-right: 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 480px;
  color: var(--light-font);
  box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2),
    0 24px 20px -24px rgba(71, 82, 107, 0.1);
  overflow: hidden;
}

.search-input {
  border: none;
  flex: 1;
  outline: none;
  height: 100%;
  padding: 0 20px;
  font-size: 16px;
  background-color: var(--search-area-bg);
  color: var(--main-color);
}

.search-input::placeholder {
  color: var(--main-color);
  opacity: 0.6;
}

.add-btn {
  color: #fff;
  background-color: var(--button-bg);
  padding: 0;
  border: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.notification-btn {
  color: var(--main-color);
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-btn {
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-left: 2px solid #ddd;
  cursor: pointer;
}

.profile-btn img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 4px;
}

.profile-btn span {
  color: var(--main-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.profile-menu {
    position: relative;
  }
.dropdown-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
    margin-top: 8px;
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    background: none;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  .dark .search-wrapper {
    box-shadow: none;
}