/* src/assets/css/addUserModal.css */
@import './variables.css';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal.open {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.modal-content {
  background-color: var(--app-container);
  color: var(--main-color);
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 1000px;
  position: relative;
  box-shadow: var(--box-shadow);
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: var(--main-color);
}

.modal-body {
  display: flex;
  gap: 20px;
}

.left-section,
.right-section {
  flex: 1;
}

.divider {
  height: 2px;
  background: var(--border-color);
  margin: 10px 0;
}

.user-additional-info .info-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-additional-info label {
  width: 150px;
  /* font-weight: bold; */
}

.user-additional-info input,
.user-additional-info select {
  flex: 1;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--search-area-bg);
  color: var(--main-color);
  border-radius: 200px;
}

.generate-btn {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: var(--button-bg);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 200px;
}

.toggle-additional {
  cursor: pointer;
  color: var(--link-color);
  margin: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.document-buttons button {
  padding: 10px 20px;
  border: 1px solid var(--border-color);
  background: none;
  cursor: pointer;
  border-radius: 4px;
  color: var(--main-color);
}

.document-buttons .active {
  background-color: var(--button-bg);
  color: #fff;
  border-color: var(--button-bg);
  border-radius: 200px;
}

.document-content .info-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.document-content label {
  width: 150px;
  /* font-weight: bold; */
}

.document-content input {
  flex: 1;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--search-area-bg);
  color: var(--main-color);
  border-radius: 200px;
}

.auto-fill-message {
  text-align: center;
  font-size: 18px;
  margin-top: 50%;
}

.modal-footer {
  margin-top: 20px;
  text-align: right;
}

.save-button {
  padding: 10px 20px;
  background-color: var(--button-bg);
  color: #fff;
  border: none;
  border-radius: 200px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.bulk-add-btn{
  padding: 10px 20px;
  background-color: var(--search-area-bg) !important;
  color: var(--main-color) !important;
    border: none;
    border-radius: 200px;
    cursor: pointer;
    margin-left: 10px;
    transition: all 0.2s ease-in-out;
}
.bulk-add-btn:hover{
  opacity: 0.8;
}