/* src/assets/css/bulkAddModal.css */
@import './variables.css';

.bulk-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100; /* Выше обычного окна */
}

.bulk-modal-content {
  background-color: var(--app-container);
  color: var(--main-color);
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 1000px;
  position: relative;
  box-shadow: var(--box-shadow);
  font-size: 14px;
}

.bulk-back-button {
  position: absolute;
  left: 20px;
  top: 20px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: var(--main-color);
}

.bulk-modal-actions {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.bulk-modal-actions button {

  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;

  padding: 10px 20px;
    background-color: var(--search-area-bg) !important;
    color: var(--main-color) !important;
    border: none;
    border-radius: 200px;
    cursor: pointer;
}

.bulk-modal-actions button:hover {
  background-color: var(--main-color);
  color: var(--search-area-bg);
}

.bulk-users-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 10px;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 5px;
}

.bulk-user-block {
  background: var(--app-container-secondary);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bulk-user-block p {
  margin: 5px 0;
  font-size: 13px;
}

.bulk-user-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.bulk-user-field label {
  font-size: 12px;
  margin-bottom: 2px;
}

.bulk-user-field input {
  padding: 5px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: var(--search-area-bg);
  color: var(--main-color);
}

.bulk-user-block strong {
  font-weight: 600;
}
textarea{
    box-sizing: border-box;
    font-size: 14px;
    padding: 10px;
    border-radius: 8px;
    background-color: transparent;
    margin-top: 20px;
    color: var(--main-color);
    max-width: 100%;
    min-width: 100%;
    max-height: 200px;
    min-height: 200px;
    border: 1px solid var(--main-color) !important;
}