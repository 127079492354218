/* src/assets/css/propiski.css */
@import './variables.css';

.propiski {
  padding: 20px;
}

.propiski-header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
}

.propiski-header input[type="text"] {
    border-radius: 20px;
    background-color: var(--search-area-bg);
    height: 40px;
    flex: 1;
    padding: 0 20px;
    font-size: 16px;
    outline: none;
    color: var(--light-font);
    box-shadow: 0 2px 6px 0 rgba(136, 148, 171, 0.2), 0 24px 20px -24px rgba(71, 82, 107, 0.1);
    overflow: hidden;
}
.dark .propiski-header input[type="text"]{
    box-shadow: none;
}
.status-filters {
  display: flex;
  gap: 10px;
}

.status-filters button {
  padding: 10px 15px;
  background-color: var(--search-area-bg);
  color: var(--main-color);
  border: none;
  border-radius: 200px;
  cursor: pointer;
}

.status-filters button.active {
  background-color: var(--main-color);
  color: var(--search-area-bg);
}

.status-filters button:hover {
  opacity: 0.8;
}

.user-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.user-card {
  background-color: var(--search-area-bg);
  color: var(--main-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  position: relative;
}

.status-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 15px;
}

.status-circle.ready {
  background-color: #17bba7;
}

.status-circle.not-ready {
  background-color: #CF2458;
}

.file-date {
  margin-right: 15px;
  font-size: 0.9rem;
  color: gray;
}

.user-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.user-info h3 {
  margin: 0;
}

.user-info p {
  margin: 5px 0;
}

.user-actions {
  margin-left: auto;
  display: flex;
  gap: 10px;
}


.action-button {
    background-color: transparent;
    border: none;
    color: var(--main-color);
    font-size: 1.5rem;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;color: var();
}

.action-button:hover {
  background-color: var(--main-color);
  color: var(--search-area-bg);
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-left {
  margin-right: auto;
}

.pagination-center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination-center button {
  padding: 5px 10px;
  background-color: var(--button-bg);
  color: var(--main-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-center button:disabled {
  opacity: 0.5;
  cursor: default;
}

.pagination-center span {
  font-size: 1rem;
}


.propiski-header input[type="text"]::placeholder {
    color: var(--main-color);
    opacity: 0.6;
  }