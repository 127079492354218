.settings-container {
    padding: 20px;
    font-family: sans-serif;
    color: var(--main-color);
    background-color: var(--app-container);
  }
  
  .settings-container h2 {
    margin-bottom: 10px;
  }
  
  .settings-tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }
  
  .settings-tab-button {
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    color: var(--main-color);
    position: relative;
  }
  
  .settings-tab-button.active {
    border-bottom: 2px solid var(--main-color);
    font-weight: bold;
  }
  
  .settings-tab-button:hover:not(.active) {
    background-color: rgba(0,0,0,0.05);
  }
  
  .settings-tab-content {
    font-size: 14px;
  }
  
  .export-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .export-buttons button {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px 15px;
    border: none;
    background-color: var(--button-bg);
    color: #fff;
    border-radius: 200px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
  }
  
  .export-buttons button:hover {
    background-color: var(--main-color);
    color: var(--search-area-bg);
  }
  
  .export-range {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
  }
  
  .range-inputs {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .range-inputs input {
    width: 100px;
    padding: 5px;
    font-size: 14px;
  }
  
  .range-inputs button {
    padding: 5px 10px;
    background-color: var(--button-bg);
    color: #fff;
    border: none;
    border-radius: 200px;
    cursor: pointer;
  }
  
  .range-inputs button:hover {
    background-color: var(--main-color);
    color: var(--search-area-bg);
  }
  