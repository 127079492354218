/* src/assets/css/dashboard.css */
@import './variables.css';

.dashboard {
    padding: 20px;
}

.stats-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.stats-card {
    flex: 1;
    background-color: var(--search-area-bg);
    color: var(--main-color);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: var(--box-shadow);
}

.stats-card .icon {
    font-size: 2rem;
    color: var(--main-color);
}

.stats-card h3 {
    margin: 0;
    font-size: 1.2rem;
}

.stats-card p {
    font-size: 1.5rem;
    font-weight: bold;
}

.main-section {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.chart-card {
    width: calc(67.67% - 13.33px);
    background-color: var(--search-area-bg);
    color: var(--main-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
}

.user-list-card {
    width: calc(33.33% - 6.67px);
    background-color: var(--search-area-bg);
    color: var(--main-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
}

.chart-card h3,
.user-list-card h3 {
    margin-bottom: 10px;
}

.user-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #3333338b;
    padding-bottom: 10px;
}

.user-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.compressed {
    max-width: 100%;
    max-height: 100%;
    image-rendering: optimizeQuality;
}

.user-item p {
    margin: 0;
    font-size: 1rem;
}

.user-item small {
    font-size: 0.8rem;
    color: #888;
}
.flexik{
    display: flex;flex-direction: column;align-items: flex-end;
}