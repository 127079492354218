/* src/assets/css/mainpage.css */
@import './variables.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

*{
    font-family: 'Montserrat', sans-serif;
}
.app-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Заполняем всю высоту окна */
    background-color: var(--app-container);
    overflow: hidden; /* Запрещаем прокрутку всей страницы */
}
  
  .app-container {
    display: flex;
    flex: 1; /* Заполняем оставшееся пространство */
    overflow: hidden; /* Запрещаем прокрутку контейнера */
}
  
  .app-content {
    flex: 1; /* Заполняем оставшееся пространство */
    overflow-y: auto; /* Разрешаем вертикальную прокрутку содержимого */
    padding: 20px; /* Добавьте отступы по необходимости */
}